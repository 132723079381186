require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  async onEnter(___arguments) {
      ___arguments.context.pageData['error'] = null;
  ___arguments.context.pageData['user'] = (
      await (async function(userIdentity){
        var userColumns = await Backendless.UserService.describeUserClass()
        var identityColumn = userColumns.find(column => column.identity)
        var whereClause = `${identityColumn.name} = '${userIdentity}'`
        var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
        var users = await Backendless.Data.of(Backendless.User).find(query)

        return users[0]
     })((___arguments.context.pageData['userIdentity']))
  );

  },  
/* handler:onEnter *//* content */
}));

define('./pages/404/components/3665111f5a5b9f8f4b064bbdf6ce7a5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/change-password/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  ['onEnter'](___arguments) {
      ___arguments.context.pageData['error'] = null;
  ___arguments.context.pageData['result'] = null;

  },
  /* handler:onEnter *//* content */
}));

define('./pages/change-password/components/58ecb77e91b7d9dd5c99075111c91c64/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['result'] = '';
  ___arguments.context.pageData['error'] = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'password')) == (getObjectProperty(___arguments.context.dataModel, 'confirm_password'))) {
    try {
      await Backendless.Request['post']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})(([(Backendless.appPath),'/change_password/',(getObjectProperty(___arguments.context.pageData, 'token'))].join('')))).setEncoding('utf8').send(({ [`password`]: (getObjectProperty(___arguments.context.dataModel, 'password')) }));
      ___arguments.context.pageData['result'] = 'Wachtwoord succesvol aangepast.';

    } catch (error) {
      ___arguments.context.pageData['error'] = ((typeof (getObjectProperty(error, 'message')) === 'string') ? (getObjectProperty(error, 'message')) : error);

    }
  } else {
    ___arguments.context.pageData['error'] = 'Passwords do not match';
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/change-password/components/9c0e75137e04bad6e50940e30ee54426/bundle.js', [], () => ({
  /* content */

  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['error'] = null;
  ___arguments.context.pageData['result'] = null;

  },
  /* handler:onChange *//* content */
}));

define('./pages/change-password/components/1e3bdf67c1f295ed344408cd7f1dba1d/bundle.js', [], () => ({
  /* content */

  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['error'] = null;
  ___arguments.context.pageData['result'] = null;

  },
  /* handler:onChange *//* content */
}));

define('./pages/logged-in/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var currentUser;


  if (!(___arguments.context.appData['currenUser'])) {
    ___arguments.context.appData['currenUser'] = (await Backendless.UserService.getCurrentUser());
  }
  currentUser = (___arguments.context.appData['currenUser']);
  if (!currentUser) {
    ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('login', null);
  }

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, stayLoggedIn, password, username, user, redirectData, redirectToPageName, sendUserTokenAndId, redirectToUrl, logging, buttonLogin;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  redirectToUrl = (getObjectProperty(___arguments.context.pageData, 'redirectToUrl'));
  redirectToPageName = (getObjectProperty(___arguments.context.pageData, 'redirectToPageName'));
  redirectData = (getObjectProperty(___arguments.context.pageData, 'redirectData'));
  sendUserTokenAndId = (getObjectProperty(___arguments.context.pageData, 'sendUserTokenAndId'));
  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
    if (redirectToUrl) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(redirectToUrl, false, (sendUserTokenAndId ? ({ 'userToken': (getObjectProperty(user, 'user-token')),'userId': (getObjectProperty(user, 'objectId')) }) : ''));
    } else if (redirectToPageName) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })(redirectToPageName, (redirectData ? (JSON.parse(redirectData)) : null));
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('logged-in', undefined);
    }
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var redirectData, redirectToPageName, redirectToUrl, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser')) ? (getObjectProperty(___arguments.context.appData, 'currentUser')) : (await Backendless.UserService.getCurrentUser(false));
  redirectToUrl = (getObjectProperty(___arguments.context.pageData, 'redirectToUrl'));
  redirectToPageName = (getObjectProperty(___arguments.context.pageData, 'redirectToPageName'));
  redirectData = (getObjectProperty(___arguments.context.pageData, 'redirectData'));
  if (currentUser) {
    if (redirectToUrl) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(redirectToUrl, false, undefined);
    } else if (redirectToPageName) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })(redirectToPageName, (redirectData ? (JSON.parse(redirectData)) : null));
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('logged-in', undefined);
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/reset-password/components/4641c8cb6f0597610bb5b1fce8828044/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async ['onClick'](___arguments) {
    var button, error, errorBlock, positive, hasError, withError, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButton(withError) {
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4641c8cb6f0597610bb5b1fce8828044'));
  button['disabled'] = (!withError);
  if (!withError) {
    button['label'] = 'Verzonden!';
  }
}


  try {
    hasError = false;
    await setError(null);
    await Backendless.UserService.restorePassword((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'emailInput')));

  } catch (error) {
    await setError((new Error('E-mail niet gevonden in het systeem. Controleer of deze goed is geschreven. Neem contact op met Springlab als het probleem zich voor blijft doen.')));
    hasError = true;

  } finally {
    await updateButton(hasError);

  }

  },
  /* handler:onClick *//* content */
}));

